.nav {
  .header {
    flex-grow: 1;

    &.mobile {
      text-align: center;
    }

    .logo {
      height: 36px;
      margin-right: 12px;
      vertical-align: sub;
    }

    .name {
      display: inline-block;
      vertical-align: sub;
    }
  }
}