.eb {
  align-items: center;
  flex-direction: row;

  &.full {
    height: 100vh;
    justify-content: center;
  }

  &.standard {
    padding: 20px 0;
  }
}